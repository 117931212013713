@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900);
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template, [hidden] {
  display: none; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ul, li {
  list-style-type: none; }

html {
  font-family: 'Heebo', sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative; }

*, *:before, *:after {
  box-sizing: border-box; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  padding: 0;
  font-size: 1.6rem;
  height: 100%; }

input,
textarea,
select,
a,
button {
  outline: none; }
  input:focus,
  textarea:focus,
  select:focus,
  a:focus,
  button:focus {
    outline: none; }

a {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

img {
  display: block;
  max-width: 100%; }

html {
  font-family: 'Montserrat', sans-serif; }

.stickyPanel {
  background-color: white;
  display: block; }

.menu-sticky {
  background-color: white;
  display: block;
  flex-flow: row wrap;
  padding: 10px 30px;
  position: relative;
  width: 100%;
  z-index: 99; }
  @media (min-width: 1080px) {
    .menu-sticky {
      flex-flow: row nowrap; } }
  .menu-sticky.is-sticky {
    position: fixed;
    top: 0;
    left: 0; }
  .menu-sticky .cont-sticky {
    align-items: center;
    display: flex;
    margin: 0 auto;
    position: relative;
    max-width: 1230px;
    justify-content: space-between; }
    @media (min-width: 1080px) {
      .menu-sticky .cont-sticky {
        justify-content: flex-start; } }
  .menu-sticky .logo {
    display: block;
    width: 135px;
    margin-bottom: 0; }
    @media (min-width: 1080px) {
      .menu-sticky .logo {
        margin-bottom: 20px; } }
    .menu-sticky .logo img {
      display: block;
      height: auto; }
  .menu-sticky .burger-menu-main {
    border-bottom: 2px solid #818181;
    border-top: 2px solid #818181;
    position: relative;
    height: 18px;
    cursor: pointer;
    display: block;
    width: 25px;
    min-width: 25px;
    margin-left: auto; }
    @media (min-width: 1080px) {
      .menu-sticky .burger-menu-main {
        display: none; } }
    .menu-sticky .burger-menu-main:after {
      background-color: #818181;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      top: 6px;
      left: 0; }
  .menu-sticky .navigation {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 1080px) {
      .menu-sticky .navigation {
        flex-grow: 1;
        margin-left: 60px; } }
    .menu-sticky .navigation .nav-menu {
      align-items: center;
      display: none;
      position: absolute;
      top: 39px;
      left: 0;
      width: 100%;
      padding: 20px;
      background-color: #fff;
      justify-content: flex-start; }
      @media (min-width: 1080px) {
        .menu-sticky .navigation .nav-menu {
          position: relative;
          top: 0;
          background-color: transparent;
          padding: 0;
          width: auto;
          display: flex; } }
      .menu-sticky .navigation .nav-menu.open {
        display: block; }
      .menu-sticky .navigation .nav-menu li {
        margin-right: 30px;
        position: relative;
        padding: 10px; }
        @media (min-width: 1080px) {
          .menu-sticky .navigation .nav-menu li {
            padding: 0; } }
        .menu-sticky .navigation .nav-menu li a {
          color: #818182;
          font-size: 14px;
          text-transform: uppercase;
          -webkit-transition: all 0.2s 0s linear;
          transition: all 0.2s 0s linear; }
        .menu-sticky .navigation .nav-menu li:hover a {
          color: #3b599a;
          font-weight: bold; }
  .menu-sticky .dropdown-menu {
    position: relative; }
    .menu-sticky .dropdown-menu .burger-menu {
      border-bottom: 2px solid #818181;
      border-top: 2px solid #818181;
      position: relative;
      height: 18px;
      cursor: pointer;
      width: 25px;
      display: none; }
      @media (min-width: 1080px) {
        .menu-sticky .dropdown-menu .burger-menu {
          display: block; } }
      .menu-sticky .dropdown-menu .burger-menu:after {
        background-color: #818181;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        top: 6px;
        left: 0; }
    .menu-sticky .dropdown-menu .dropdown-nav {
      background-color: #fff;
      box-shadow: 0px -7px 56px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      display: none;
      padding: 15px;
      position: absolute;
      top: 45px;
      right: -10px; }
      .menu-sticky .dropdown-menu .dropdown-nav.open {
        display: block; }
      .menu-sticky .dropdown-menu .dropdown-nav:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-bottom: 15px solid #FFF;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: relative;
        margin: -30px 5px 15px auto; }
      .menu-sticky .dropdown-menu .dropdown-nav ul li {
        position: relative;
        padding: 5px; }
        .menu-sticky .dropdown-menu .dropdown-nav ul li a {
          color: #818182;
          font-size: 14px;
          text-transform: uppercase;
          -webkit-transition: all 0.2s 0s linear;
          transition: all 0.2s 0s linear; }
        .menu-sticky .dropdown-menu .dropdown-nav ul li:hover a {
          color: #3b599a;
          font-weight: bold; }

.plataforma-section {
  background-color: #f9f6f4;
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .plataforma-section {
      padding: 100px 0; } }
  .plataforma-section .plataforma-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 50px; }
    .plataforma-section .plataforma-topo h2 {
      color: #616061;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300;
      line-height: 1.2; }
      @media (min-width: 1080px) {
        .plataforma-section .plataforma-topo h2 {
          font-size: 62px; } }
    .plataforma-section .plataforma-topo p {
      color: #616061;
      font-size: 14px; }
  .plataforma-section .container {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1230px;
    padding: 30px;
    width: 100%; }
    .plataforma-section .container .plataforma-thumb {
      width: 100%;
      margin-bottom: 50px;
      position: relative; }
      @media (min-width: 1080px) {
        .plataforma-section .container .plataforma-thumb {
          margin-bottom: 0;
          width: 25%; } }
      .plataforma-section .container .plataforma-thumb .swiper-slide {
        cursor: pointer;
        display: block;
        position: relative;
        max-width: 145px; }
        .plataforma-section .container .plataforma-thumb .swiper-slide:after {
          background-color: #e87f07;
          content: '';
          display: none;
          height: 100%;
          position: absolute;
          top: 0;
          left: 35px;
          width: 1px;
          z-index: 1; }
          @media (min-width: 1080px) {
            .plataforma-section .container .plataforma-thumb .swiper-slide:after {
              display: block; } }
        .plataforma-section .container .plataforma-thumb .swiper-slide:last-child:after {
          display: none; }
        .plataforma-section .container .plataforma-thumb .swiper-slide .slide-icon {
          align-items: center;
          display: flex;
          flex-flow: row nowrap;
          margin-bottom: 30px;
          position: relative;
          z-index: 2; }
          .plataforma-section .container .plataforma-thumb .swiper-slide .slide-icon .icon {
            align-items: center;
            border: 1px solid #e87f07;
            background-color: #e87f07;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            height: 35px;
            min-height: 35px;
            padding: 7px;
            overflow: hidden;
            width: 35px;
            min-width: 35px; }
            @media (min-width: 500px) {
              .plataforma-section .container .plataforma-thumb .swiper-slide .slide-icon .icon {
                padding: 0;
                min-height: 70px;
                min-width: 70px;
                height: 70px;
                width: 70px; } }
            .plataforma-section .container .plataforma-thumb .swiper-slide .slide-icon .icon img {
              display: block;
              max-width: 100%;
              height: auto; }
          .plataforma-section .container .plataforma-thumb .swiper-slide .slide-icon p {
            display: block;
            color: #e87f07;
            font-size: 12px;
            margin-left: 10px;
            text-transform: uppercase; }
        .plataforma-section .container .plataforma-thumb .swiper-slide:hover .icon, .plataforma-section .container .plataforma-thumb .swiper-slide.swiper-slide-thumb-active .icon {
          background-color: #3b599a; }
        .plataforma-section .container .plataforma-thumb .swiper-slide:hover p, .plataforma-section .container .plataforma-thumb .swiper-slide.swiper-slide-thumb-active p {
          color: #3b599a; }
    .plataforma-section .container .plataforma-slide {
      width: 100%; }
      @media (min-width: 1080px) {
        .plataforma-section .container .plataforma-slide {
          width: 75%; } }
      .plataforma-section .container .plataforma-slide .slide-cont {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        height: 100%; }
        @media (min-width: 1080px) {
          .plataforma-section .container .plataforma-slide .slide-cont {
            flex-flow: row nowrap; } }
        .plataforma-section .container .plataforma-slide .slide-cont .slide-texto {
          margin-right: 50px;
          width: 100%; }
          @media (min-width: 1080px) {
            .plataforma-section .container .plataforma-slide .slide-cont .slide-texto {
              width: 27%; } }
          .plataforma-section .container .plataforma-slide .slide-cont .slide-texto h2 {
            color: #3b599a;
            font-size: 35px;
            font-weight: 400;
            line-height: 1.1;
            margin-bottom: 15px;
            padding-bottom: 15px;
            position: relative; }
            @media (min-width: 1080px) {
              .plataforma-section .container .plataforma-slide .slide-cont .slide-texto h2 {
                margin-bottom: 30px;
                padding-bottom: 30px; } }
            .plataforma-section .container .plataforma-slide .slide-cont .slide-texto h2:after {
              background-color: #3b599a;
              bottom: 0;
              content: '';
              display: block;
              height: 2px;
              left: 0;
              position: absolute;
              width: 37%; }
          .plataforma-section .container .plataforma-slide .slide-cont .slide-texto p {
            color: #666;
            font-size: 14px; }
        .plataforma-section .container .plataforma-slide .slide-cont .slide-image {
          display: block;
          width: 100%; }

.produtores-section {
  background-color: #3b599a;
  background-image: -webkit-gradient(linear, left top, right top, from(#3b599a), to(#2c5eca));
  background-image: -webkit-linear-gradient(left, #3b599a, #2c5eca);
  background-image: linear-gradient(to right, #3b599a, #2c5eca);
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .produtores-section {
      padding: 60px 0; } }
  .produtores-section .produtores-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 20px; }
    .produtores-section .produtores-topo h2 {
      color: white;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 10px; }
      @media (min-width: 1080px) {
        .produtores-section .produtores-topo h2 {
          font-size: 62px; } }
    .produtores-section .produtores-topo p {
      color: white;
      font-size: 18px; }
  .produtores-section .container {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1230px;
    padding: 30px 30px 0px 30px;
    width: 100%; }
    .produtores-section .container .produtores-slide {
      display: block;
      margin-bottom: 20px;
      width: 100%; }
      .produtores-section .container .produtores-slide .slide-cont {
        align-items: center;
        flex-flow: row wrap;
        display: flex;
        justify-content: space-between;
        width: 100%; }
        @media (min-width: 1080px) {
          .produtores-section .container .produtores-slide .slide-cont {
            flex-flow: row nowrap; } }
        .produtores-section .container .produtores-slide .slide-cont .produtor {
          display: block;
          max-width: 215px;
          margin: 0 auto 20px; }
          @media (min-width: 1080px) {
            .produtores-section .container .produtores-slide .slide-cont .produtor {
              margin: 0; } }
          .produtores-section .container .produtores-slide .slide-cont .produtor .avatar {
            border-radius: 50%;
            box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.5);
            display: block;
            height: 215px;
            margin-bottom: 25px;
            overflow: hidden;
            width: 215px; }
            .produtores-section .container .produtores-slide .slide-cont .produtor .avatar img {
              display: block;
              height: 100%;
              object-fit: cover;
              width: 100%; }
          .produtores-section .container .produtores-slide .slide-cont .produtor .nome {
            color: white;
            display: block;
            font-size: 26px;
            text-align: center;
            width: 100%; }
            .produtores-section .container .produtores-slide .slide-cont .produtor .nome:before {
              background-color: #fff;
              content: '';
              display: block;
              height: 1px;
              margin: 0 auto 10px;
              width: 55px; }
        .produtores-section .container .produtores-slide .slide-cont .producoes {
          align-items: stretch;
          display: flex;
          flex-grow: 1;
          flex-flow: row wrap;
          justify-content: space-between; }
          @media (min-width: 1080px) {
            .produtores-section .container .produtores-slide .slide-cont .producoes {
              padding-left: 50px;
              flex-flow: row nowrap; } }
          .produtores-section .container .produtores-slide .slide-cont .producoes .producao {
            display: block;
            margin-bottom: 4%;
            width: 48%; }
            @media (min-width: 1080px) {
              .produtores-section .container .produtores-slide .slide-cont .producoes .producao {
                margin-bottom: 0;
                width: 24%; } }
            .produtores-section .container .produtores-slide .slide-cont .producoes .producao img {
              display: block; }
    .produtores-section .container .produtores-slide-nav {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%; }
      .produtores-section .container .produtores-slide-nav .produtores-prev, .produtores-section .container .produtores-slide-nav .produtores-next {
        cursor: pointer;
        margin: 10px;
        outline: none; }

.artista-section {
  background-color: #f9f6f4;
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .artista-section {
      padding: 100px 0; } }
  .artista-section .artista-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 50px; }
    .artista-section .artista-topo h2 {
      color: #616061;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300;
      line-height: 1.2; }
      @media (min-width: 1080px) {
        .artista-section .artista-topo h2 {
          font-size: 62px; } }
    .artista-section .artista-topo p {
      color: #616061;
      font-size: 14px; }
  .artista-section .container {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1230px;
    padding: 30px;
    width: 100%; }
    .artista-section .container .artista-slide {
      display: block;
      height: 100%;
      margin-bottom: 50px;
      width: 100%; }
      @media (min-width: 1080px) {
        .artista-section .container .artista-slide {
          margin-bottom: 100px; } }
      .artista-section .container .artista-slide .slide-cont {
        align-items: center;
        flex-flow: row nowrap;
        display: flex;
        justify-content: flex-start;
        width: 100%; }
        .artista-section .container .artista-slide .slide-cont .avatar {
          border-radius: 50%;
          box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.5);
          display: block;
          height: 125px;
          min-height: 125px;
          margin-bottom: 25px;
          overflow: hidden;
          width: 125px;
          min-width: 125px; }
          .artista-section .container .artista-slide .slide-cont .avatar img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%; }
        .artista-section .container .artista-slide .slide-cont .texto {
          color: #3b599a;
          display: block;
          font-size: 26px;
          margin-left: 15px; }
          .artista-section .container .artista-slide .slide-cont .texto:after {
            background-color: #3b599a;
            content: '';
            display: block;
            height: 1px;
            margin: 10px 0 10px;
            width: 55px; }
    .artista-section .container .artista-slide-nav {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%; }
      .artista-section .container .artista-slide-nav .artista-prev, .artista-section .container .artista-slide-nav .artista-next {
        cursor: pointer;
        margin: 10px;
        outline: none; }

.modal {
  align-content: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  padding: 10vh 20px;
  top: 0;
  width: 100%;
  z-index: 999; }
  .modal .modal-content {
    background-color: #333;
    display: block;
    max-width: 782px;
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%; }
  .modal .close-modal {
    color: white;
    cursor: pointer;
    font-size: 20px; }
  .modal.open {
    display: flex; }

.tv-ooppah {
  background-color: #000;
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .tv-ooppah {
      background-image: url(/static/media/bg-tv.695de533.jpg);
      background-size: cover;
      background-position: top right;
      padding: 150px 0; } }
  .tv-ooppah .conteudo {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    max-width: 1230px;
    padding: 30px;
    width: 100%; }
    @media (min-width: 1080px) {
      .tv-ooppah .conteudo {
        flex-flow: row nowrap; } }
    .tv-ooppah .conteudo .imagem {
      display: block;
      margin: 0 auto 30px;
      width: 50%; }
      @media (min-width: 1080px) {
        .tv-ooppah .conteudo .imagem {
          margin-bottom: 0;
          width: 35%; } }
      .tv-ooppah .conteudo .imagem img {
        display: block;
        width: 100%; }
    .tv-ooppah .conteudo .texto {
      display: block;
      width: 100%; }
      .tv-ooppah .conteudo .texto .image-size {
        width: 450px; }
      @media (min-width: 1080px) {
        .tv-ooppah .conteudo .texto {
          width: 42%; } }
      .tv-ooppah .conteudo .texto .topo-tv {
        margin-bottom: 15px; }
      .tv-ooppah .conteudo .texto .titulo {
        color: white;
        font-size: 43px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.4;
        margin-bottom: 30px;
        padding-bottom: 40px;
        position: relative; }
        .tv-ooppah .conteudo .texto .titulo:after {
          background-color: #9e1016;
          bottom: 0;
          content: '';
          display: block;
          height: 5px;
          left: 0;
          position: absolute;
          width: 37%; }
      .tv-ooppah .conteudo .texto p {
        font-size: 18px;
        color: white;
        margin-bottom: 20px;
        line-height: 1.6; }
      .tv-ooppah .conteudo .texto .btn-play {
        align-items: center;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        margin-right: 30px;
        padding: 15px;
        text-transform: uppercase;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content; }
        .tv-ooppah .conteudo .texto .btn-play:hover {
          border: 1px solid #9e1016;
          background-color: rgba(255, 255, 255, 0.5);
          color: #9e1016; }
        .tv-ooppah .conteudo .texto .btn-play .icon {
          margin-right: 7px; }
        .tv-ooppah .conteudo .texto .btn-play .text {
          font-size: 14px; }

.depoimentos-section {
  background-color: #3b599a;
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .depoimentos-section {
      padding: 60px 0; } }
  .depoimentos-section .depoimentos-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 20px; }
    @media (min-width: 1080px) {
      .depoimentos-section .depoimentos-topo {
        margin-bottom: 0px; } }
    .depoimentos-section .depoimentos-topo h2 {
      color: #fff;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300; }
      @media (min-width: 1080px) {
        .depoimentos-section .depoimentos-topo h2 {
          font-size: 62px; } }
    .depoimentos-section .depoimentos-topo p {
      color: #fff;
      font-size: 14px; }
  .depoimentos-section .container {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1230px;
    padding: 10px 30px;
    width: 100%; }
    @media (min-width: 1080px) {
      .depoimentos-section .container {
        padding: 30px 30px 0 30px; } }
    .depoimentos-section .container .depoimentos-slide {
      display: block;
      height: 100%;
      margin-bottom: 50px;
      box-sizing: border-box;
      width: 100%; }
      @media (min-width: 1080px) {
        .depoimentos-section .container .depoimentos-slide {
          margin-bottom: 10px; } }
      .depoimentos-section .container .depoimentos-slide .swiper-wrapper {
        align-items: center; }
      .depoimentos-section .container .depoimentos-slide .swiper-slide {
        box-sizing: border-box;
        padding: 10px; }
      .depoimentos-section .container .depoimentos-slide .slide-cont {
        align-items: center;
        align-content: center;
        flex-flow: column nowrap;
        display: flex;
        justify-content: flex-start;
        width: 100%; }
        .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimento {
          background-color: #fff;
          padding: 20px 35px 100px;
          border-radius: 5px;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1); }
          .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimento img {
            margin: auto; }
        .depoimentos-section .container .depoimentos-slide .slide-cont .depoimento-avatar {
          border-radius: 50%;
          display: block;
          height: 125px;
          min-height: 125px;
          margin-top: -65px;
          overflow: hidden;
          width: 125px;
          min-width: 125px; }
          .depoimentos-section .container .depoimentos-slide .slide-cont .depoimento-avatar img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%; }
        .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimentos-texto {
          color: #666;
          padding: 20px 0;
          text-align: center; }
        .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimento-nome {
          color: #3b599a;
          display: block;
          font-size: 26px;
          text-align: center;
          margin-left: 15px; }
          .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimento-nome span {
            display: block;
            width: 100%;
            font-size: 14px;
            color: #666666;
            text-transform: uppercase; }
          .depoimentos-section .container .depoimentos-slide .slide-cont .quadro-depoimento-nome:before {
            background-color: #3b599a;
            content: '';
            display: block;
            height: 1px;
            margin: 10px auto 10px;
            width: 55px; }
    .depoimentos-section .container .depoimentos-slide-nav {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%; }
      .depoimentos-section .container .depoimentos-slide-nav .depoimentos-prev, .depoimentos-section .container .depoimentos-slide-nav .depoimentos-next {
        cursor: pointer;
        margin: 10px;
        outline: none; }

.footer {
  display: flex;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap; }
  .footer .footer-logo {
    margin-bottom: 20px; }
  .footer .footer-nav {
    margin-bottom: 20px;
    max-width: 1200px; }
    .footer .footer-nav ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between; }
      .footer .footer-nav ul li {
        padding: 10px;
        width: 48%; }
        @media (min-width: 1080px) {
          .footer .footer-nav ul li {
            width: auto; } }
        .footer .footer-nav ul li a {
          color: #666;
          text-transform: uppercase;
          font-size: 14px; }
  .footer .footer-social {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 20px; }
    .footer .footer-social a {
      padding: 5px; }
  .footer .footer-copy {
    text-align: center;
    color: #666666;
    font-size: 12px; }

.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px; }
  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 6px;
    border: 3px solid #a7a7a8;
    border-radius: 50%;
    -webkit-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #A7A7A8 transparent transparent transparent; }
  .spinner div:nth-child(1) {
    -webkit-animation-delay: -0.30s;
            animation-delay: -0.30s; }
  .spinner div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .spinner div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.form-input {
  display: block;
  padding-bottom: 15px;
  width: 100%; }
  .form-input .error-msg {
    font-size: 10px;
    color: red; }
  .form-input .input {
    display: block;
    height: 50px;
    margin-bottom: 10px;
    position: relative;
    width: 100%; }
    .form-input .input .spinner {
      position: absolute;
      right: 20px;
      top: 9px; }
    .form-input .input input {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: transparent;
      border: 1px solid #818182;
      border-radius: 7px;
      padding: 15px 10px 10px;
      margin: 0;
      height: 100%;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      position: relative;
      z-index: 2; }
    .form-input .input input:disabled {
      border: 1px solid #ccc; }
      .form-input .input input:disabled + .label {
        color: #ccc; }
    .form-input .input .label {
      position: absolute;
      top: 17px;
      left: 11px;
      font-size: 12px;
      -webkit-transform: all 0.3s 0s linear;
              transform: all 0.3s 0s linear;
      z-index: 1; }
      .form-input .input .label.label-up {
        top: 7px;
        font-size: 10px;
        -webkit-transform: all 0.3s 0s linear;
                transform: all 0.3s 0s linear; }

.coupon-form {
  padding-top: 30px;
  margin-bottom: 50px; }
  .coupon-form .text-container {
    font-size: 12px;
    font-weight: 300;
    text-align: center; }
    .coupon-form .text-container a {
      font-weight: 600;
      color: dodgerblue; }
  .coupon-form .form-container {
    width: 100%;
    margin: 20px auto 0 auto; }
    @media (min-width: 1080px) {
      .coupon-form .form-container {
        width: 50%; } }
    .coupon-form .form-container p {
      text-align: center; }
    .coupon-form .form-container .label {
      width: 96%;
      text-align: center;
      top: 23px; }
    .coupon-form .form-container input[type="text"] {
      text-align: center;
      padding: 30px;
      border-color: #ccc; }
    .coupon-form .form-container .spinner {
      top: 15px; }
    .coupon-form .form-container .btn-cancel {
      display: block;
      text-align: center;
      padding: 20px 0;
      font-size: 11px; }
  .coupon-form .form-input .error-msg {
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: 30px; }
  .coupon-form .form-input {
    width: 85%;
    float: left; }
    .coupon-form .form-input input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0 !important; }
  .coupon-form .btn-validate {
    width: 15%;
    padding: 24px 10px;
    border: 1px solid #3b599a;
    font-size: 11px;
    border-right-width: 0 !important;
    background: #3b599a;
    color: #fff;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer; }

.planos-section {
  background-color: #f9f6f4;
  display: block;
  padding: 50px 0 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .planos-section {
      padding: 100px 0; } }
  .planos-section .planos-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 50px; }
    .planos-section .planos-topo h2 {
      color: #616061;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300; }
      @media (min-width: 1080px) {
        .planos-section .planos-topo h2 {
          font-size: 62px; } }
    .planos-section .planos-topo p {
      color: #616061;
      font-size: 14px; }
  .planos-section .container {
    display: block;
    margin: auto;
    max-width: 1230px;
    padding: 10px 30px;
    width: 100%; }
    @media (min-width: 1080px) {
      .planos-section .container {
        padding: 30px; } }
  .planos-section .planos-tipo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .planos-section .planos-tipo .btn {
      background-color: #ffffff;
      border-radius: 40px;
      cursor: pointer;
      font-size: 10px;
      margin: 10px;
      padding: 15px 50px;
      text-transform: uppercase; }
      @media (min-width: 1080px) {
        .planos-section .planos-tipo .btn {
          font-size: 12px; } }
      .planos-section .planos-tipo .btn.btn-adulto {
        background-color: #3b599a;
        color: white;
        text-align: center; }
      .planos-section .planos-tipo .btn.btn-mirim {
        background-color: #e78724;
        color: white;
        text-align: center; }

.planos-form {
  display: block;
  padding: 20px 0 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .planos-form {
      padding: 20px 0; } }
  .planos-form .input input .nota {
    text-align: center;
    margin-bottom: 50px;
    font-size: 12px; }
  .planos-form .lista-planos {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%; }
    .planos-form .lista-planos .bloco-plano {
      border: 1px solid #3b599a;
      display: block;
      margin-bottom: 20px;
      padding: 40px 40px;
      width: 100%; }
      @media (min-width: 1080px) {
        .planos-form .lista-planos .bloco-plano {
          width: 30%; } }
      .planos-form .lista-planos .bloco-plano .bloco-plano-cont {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center; }
        .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-topo {
          font-size: 12px;
          margin-bottom: 30px;
          text-align: center;
          text-transform: uppercase; }
          .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-topo .tipo {
            color: #3b599a;
            font-weight: 700; }
          .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-topo .pagamento {
            color: #333; }
        .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-preco {
          margin-bottom: 10px;
          text-transform: uppercase;
          text-align: center; }
          .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-preco p {
            color: #333;
            font-size: 12px; }
          .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-preco .destaque {
            color: #3b599a;
            font-size: 24px;
            padding-bottom: 20px; }
            .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-preco .destaque:after {
              background-color: #3b599a;
              content: '';
              display: block;
              height: 1px;
              margin: 10px auto;
              width: 55px; }
        .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-plano-descricao {
          font-size: 12px;
          text-align: center;
          max-width: 70%;
          margin-bottom: 30px;
          text-transform: uppercase; }
        .planos-form .lista-planos .bloco-plano .bloco-plano-cont .bloco-btn-cadastrar {
          background-color: transparent;
          border-radius: 40px;
          border: 1px solid #3b599a;
          font-size: 12px;
          margin: 10px;
          padding: 20px 35px;
          text-transform: uppercase;
          color: #3b599a;
          cursor: pointer; }
      .planos-form .lista-planos .bloco-plano:hover {
        cursor: pointer; }
        .planos-form .lista-planos .bloco-plano:hover .bloco-plano-topo .pagamento {
          color: #3b599a; }
        .planos-form .lista-planos .bloco-plano:hover .bloco-plano-preco p, .planos-form .lista-planos .bloco-plano:hover .bloco-plano-descricao p {
          color: #3b599a; }
        .planos-form .lista-planos .bloco-plano:hover .bloco-btn-cadastrar {
          background-color: #3b599a;
          color: white; }
  .planos-form .formulario-artista {
    display: block;
    width: 100%; }
    .planos-form .formulario-artista .titulo-bloco {
      color: #3b599a;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 5px;
      text-transform: uppercase; }
    .planos-form .formulario-artista .linha {
      align-items: stretch;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      flex-flow: wrap; }
      @media (min-width: 1080px) {
        .planos-form .formulario-artista .linha {
          flex-flow: nowrap; } }
      .planos-form .formulario-artista .linha .w-1 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-1 {
            width: 8%; } }
      .planos-form .formulario-artista .linha .w-2 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-2 {
            width: 18%; } }
      .planos-form .formulario-artista .linha .w-3 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-3 {
            width: 28%; } }
      .planos-form .formulario-artista .linha .w-4 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-4 {
            width: 38%; } }
      .planos-form .formulario-artista .linha .w-5 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-5 {
            width: 48%; } }
      .planos-form .formulario-artista .linha .w-6 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-6 {
            width: 58%; } }
      .planos-form .formulario-artista .linha .w-7 {
        width: 100%; }
        @media (min-width: 1080px) {
          .planos-form .formulario-artista .linha .w-7 {
            width: 68%; } }
    .planos-form .formulario-artista .pagamento {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin: 0 auto 30px;
      max-width: 400px; }
      .planos-form .formulario-artista .pagamento .topo-pagamento {
        display: block;
        margin-bottom: 30px;
        max-width: 400px;
        text-align: center; }
        .planos-form .formulario-artista .pagamento .topo-pagamento .pagamento-titulo {
          font-size: 17px;
          font-weight: 700;
          color: #3b599a; }
      .planos-form .formulario-artista .pagamento .pagamento-texto {
        font-size: 14px;
        text-align: center; }
      .planos-form .formulario-artista .pagamento .image-pagamento {
        margin-bottom: 30px; }
    .planos-form .formulario-artista .registro-assinatura {
      display: block;
      width: 100%; }
      .planos-form .formulario-artista .registro-assinatura .registro-assinatura-topo {
        display: block;
        margin-bottom: 30px;
        text-align: center; }
        .planos-form .formulario-artista .registro-assinatura .registro-assinatura-topo .assinatura-topo-titulo {
          font-size: 17px;
          font-weight: 700;
          color: #3b599a; }
        .planos-form .formulario-artista .registro-assinatura .registro-assinatura-topo .assinatura-topo-texto {
          font-size: 14px;
          text-align: center; }
      .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont {
        display: block;
        width: 100%; }
        .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .linha {
          align-items: stretch;
          display: flex;
          flex-flow: row wrap; }
        .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco {
          display: block;
          margin-bottom: 10px;
          width: 100%; }
          .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco.bloco-3 {
            display: block;
            width: 100%; }
            @media (min-width: 1080px) {
              .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco.bloco-3 {
                width: 30%; } }
          .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-topo {
            color: #3b599a;
            font-size: 17px;
            font-weight: 700;
            margin-bottom: 5px;
            text-transform: uppercase; }
          .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont {
            border: 1px solid #cdcbca;
            border-radius: 5px;
            display: block;
            padding: 20px;
            width: 100%; }
            .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont.laranja {
              background-color: #3b599a;
              color: white; }
            .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .selecionado {
              align-items: center;
              color: white;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              text-align: center; }
              .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .selecionado h2 {
                font-size: 17px;
                font-weight: 700;
                text-transform: uppercase; }
              .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .selecionado p {
                font-size: 14px; }
            .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha {
              display: flex;
              width: 100%;
              justify-content: space-between;
              flex-flow: row wrap; }
              .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .texto {
                font-size: 14px;
                margin-bottom: 10px; }
                .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .texto .sinal {
                  color: #3b599a; }
              .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .bloco-column {
                display: block;
                margin-bottom: 15px;
                width: 100%; }
                @media (min-width: 1080px) {
                  .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .bloco-column {
                    width: 48%; } }
                .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .bloco-column .bloco-titulo {
                  font-size: 16px;
                  color: #cdcbca;
                  display: block;
                  margin-bottom: 5px;
                  text-transform: uppercase;
                  width: 100%; }
                .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .bloco-column .texto-label {
                  font-size: 10px; }
                .planos-form .formulario-artista .registro-assinatura .registro-assinatura-cont .bloco .bloco-cont .bloco-cont-linha .bloco-column.bloco-column-last {
                  width: 100%; }
  .planos-form .rodape {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap; }
    @media (min-width: 1080px) {
      .planos-form .rodape {
        flex-wrap: nowrap; } }
    .planos-form .rodape .etapa {
      display: flex;
      margin-bottom: 10px; }
      .planos-form .rodape .etapa .numero {
        align-items: center;
        background-color: #f9f6f4;
        border-radius: 50%;
        border: 1px solid #cdcbca;
        color: #cdcbca;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        height: 25px;
        justify-content: center;
        margin: 5px;
        overflow: hidden;
        width: 25px; }
        .planos-form .rodape .etapa .numero.ativo, .planos-form .rodape .etapa .numero:hover {
          border: 1px solid #3b599a;
          color: #3b599a; }
    .planos-form .rodape .plano-selecionado {
      margin-bottom: 10px; }
      .planos-form .rodape .plano-selecionado .topo-titulo {
        font-size: 10px;
        text-transform: uppercase; }
      .planos-form .rodape .plano-selecionado .destaque {
        color: #3b599a;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase; }
    .planos-form .rodape .plano-termos {
      text-transform: uppercase;
      font-size: 12px;
      display: flex;
      max-width: 250px;
      align-items: center; }
      .planos-form .rodape .plano-termos label {
        margin-left: 10px;
        color: #666; }
      .planos-form .rodape .plano-termos a {
        color: #666;
        text-decoration: underline; }
    .planos-form .rodape .btn {
      cursor: pointer;
      background-color: #3b599a;
      border-radius: 40px;
      border: 1px solid #3b599a;
      font-size: 17px;
      padding: 25px 55px;
      text-transform: uppercase;
      color: #FFF;
      text-align: center;
      width: 100%; }
      @media (min-width: 1080px) {
        .planos-form .rodape .btn {
          width: auto; } }
  .planos-form.adulto .lista-planos .bloco-plano {
    border: 1px solid #3b599a; }
  .planos-form.adulto .input input {
    border: 1px solid #3b599a;
    color: #3b599a; }
  .planos-form.minor .formulario-artista .titulo-bloco {
    color: #e78724; }
  .planos-form.minor .lista-planos .bloco-plano {
    border: 1px solid #e78724; }
    .planos-form.minor .lista-planos .bloco-plano:hover {
      color: #e78724; }
      .planos-form.minor .lista-planos .bloco-plano:hover .bloco-plano-cont .bloco-plano-topo .pagamento {
        color: #e78724; }
      .planos-form.minor .lista-planos .bloco-plano:hover .bloco-plano-cont .bloco-plano-preco p {
        color: #e78724; }
      .planos-form.minor .lista-planos .bloco-plano:hover .bloco-plano-cont .bloco-plano-descricao p {
        color: #e78724; }
      .planos-form.minor .lista-planos .bloco-plano:hover .bloco-plano-cont button.bloco-btn-cadastrar {
        background: #e78724;
        border-color: #e78724;
        color: #fff; }
    .planos-form.minor .lista-planos .bloco-plano .bloco-plano-topo .tipo {
      color: #e78724; }
    .planos-form.minor .lista-planos .bloco-plano .bloco-plano-preco .destaque {
      color: #e78724; }
      .planos-form.minor .lista-planos .bloco-plano .bloco-plano-preco .destaque::after {
        background: #e78724; }
    .planos-form.minor .lista-planos .bloco-plano button.bloco-btn-cadastrar {
      border-color: #e78724;
      color: #e78724; }
  .planos-form.minor .input input {
    color: #e78724; }
  .planos-form.minor .rodape .plano-selecionado .destaque {
    color: #e78724; }
  .planos-form.minor .rodape .btn {
    background: #e78724;
    border-color: #e78724; }
  .planos-form.minor .rodape .etapa .numero {
    border-color: #e78724;
    color: #e78724; }
    .planos-form.minor .rodape .etapa .numero.ativo, .planos-form.minor .rodape .etapa .numero:hover {
      background: #e78724;
      color: #fff; }

.flash-message {
  padding: 15px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 30px; }
  .flash-message ul li {
    list-style: square;
    margin-bottom: 5px;
    margin-left: 30px; }

.minor-alert {
  color: red;
  text-align: center;
  width: 70%;
  margin: 0 auto; }
  .minor-alert strong {
    font-weight: 600; }
  .minor-alert p {
    font-weight: 300;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px; }
  .minor-alert .text-justify {
    text-align: justify; }
  .minor-alert .authorization-link {
    text-decoration: underline;
    color: blue; }

.quemsomos-section {
  background-color: #e87f07;
  display: block;
  padding: 50px 0;
  width: 100%; }
  @media (min-width: 1080px) {
    .quemsomos-section {
      padding: 60px 0; } }
  .quemsomos-section .quemsomos-topo {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 20px; }
    @media (min-width: 1080px) {
      .quemsomos-section .quemsomos-topo {
        margin-bottom: 0px; } }
    .quemsomos-section .quemsomos-topo h2 {
      color: #fff;
      font-size: 42px;
      display: block;
      width: 100%;
      font-weight: 300; }
      @media (min-width: 1080px) {
        .quemsomos-section .quemsomos-topo h2 {
          font-size: 62px; } }
    .quemsomos-section .quemsomos-topo p {
      color: #fff;
      font-size: 14px; }
  .quemsomos-section .container {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1230px;
    padding: 10px 30px;
    width: 100%; }
    @media (min-width: 1080px) {
      .quemsomos-section .container {
        padding: 30px 30px 0px 30px; } }
    .quemsomos-section .container .quemsomos-slide {
      display: block;
      height: 100%;
      margin-bottom: 50px;
      box-sizing: border-box;
      width: 100%; }
      @media (min-width: 1080px) {
        .quemsomos-section .container .quemsomos-slide {
          margin-bottom: 10px; } }
      .quemsomos-section .container .quemsomos-slide .swiper-wrapper {
        align-items: center; }
      .quemsomos-section .container .quemsomos-slide .swiper-slide {
        box-sizing: border-box;
        padding: 10px; }
      .quemsomos-section .container .quemsomos-slide .slide-cont {
        align-items: center;
        align-content: center;
        flex-flow: column nowrap;
        display: flex;
        justify-content: flex-start;
        width: 100%; }
        .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos {
          background-color: #fff;
          padding: 20px 35px 100px;
          border-radius: 5px;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1); }
          .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos img {
            margin: auto; }
        .quemsomos-section .container .quemsomos-slide .slide-cont .quemsomos-avatar {
          border-radius: 50%;
          display: block;
          height: 125px;
          min-height: 125px;
          margin-top: -65px;
          overflow: hidden;
          width: 125px;
          min-width: 125px; }
          .quemsomos-section .container .quemsomos-slide .slide-cont .quemsomos-avatar img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%; }
        .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos-texto {
          color: #666;
          padding: 20px 0;
          text-align: center; }
        .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos-nome {
          color: #3b599a;
          display: block;
          font-size: 26px;
          text-align: center;
          margin-left: 15px; }
          .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos-nome span {
            display: block;
            width: 100%;
            font-size: 14px;
            color: #666666;
            text-transform: uppercase; }
          .quemsomos-section .container .quemsomos-slide .slide-cont .quadro-quemsomos-nome:before {
            background-color: #3b599a;
            content: '';
            display: block;
            height: 1px;
            margin: 10px auto 10px;
            width: 55px; }
    .quemsomos-section .container .quemsomos-slide-nav {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%; }
      .quemsomos-section .container .quemsomos-slide-nav .quemsomos-prev, .quemsomos-section .container .quemsomos-slide-nav .quemsomos-next {
        cursor: pointer;
        margin: 10px;
        outline: none; }

.basic-css h1 {
    display: block;
    font-size: 2em;
    -webkit-margin-before: 0.67em;
            margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.basic-css h2 {
    display: block;
    font-size: 1.5em;
    -webkit-margin-before: 0.83em;
            margin-block-start: 0.83em;
    -webkit-margin-after: 0.83em;
            margin-block-end: 0.83em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.basic-css h3 {
    display: block;
    font-size: 1.17em;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.basic-css h4 {
    display: block;
    -webkit-margin-before: 1.33em;
            margin-block-start: 1.33em;
    -webkit-margin-after: 1.33em;
            margin-block-end: 1.33em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.basic-css h5 {
    display: block;
    font-size: 0.83em;
    -webkit-margin-before: 1.67em;
            margin-block-start: 1.67em;
    -webkit-margin-after: 1.67em;
            margin-block-end: 1.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;

}

.basic-css h6 {
    display: block;
    font-size: 0.67em;
    -webkit-margin-before: 2.33em;
            margin-block-start: 2.33em;
    -webkit-margin-after: 2.33em;
            margin-block-end: 2.33em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.basic-css ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 40px;
            padding-inline-start: 40px;
}

.basic-css ol li {
    list-style-type: decimal;
}

.basic-css ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 40px;
            padding-inline-start: 40px;
}


.basic-css ul li {
    list-style-type: disc;
}

.basic-css p{
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

.basic-css li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.basic-css strong, b {
    font-weight: bold;
}

.basic-css i {
    font-style: italic;
}

.basic-css u {
    text-decoration: underline;
}

.basic-css blockquote {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 40px;
            margin-inline-start: 40px;
    -webkit-margin-end: 40px;
            margin-inline-end: 40px;
}

.basic-css a {
    color:#e78724;
}
